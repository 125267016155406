<template>
  <v-card outlined>
    <v-toolbar dense dark color="primary darken-1" elevation="0">
      <v-toolbar-title v-text="'Nearby events'" />
    </v-toolbar>

    <v-card-text>
      <span v-if="!nearbyEvents">This incident is not in range of 50 metres from any event.</span>
      <template v-else>
        <v-data-table
          :headers="headers"
          :items="nearbyEvents"
          dense
          disable-filtering
          disable-pagination
          disable-sort
          hide-default-footer
        >
          <template #no-data>
            <span>No nearby events</span>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'pibot-eprs-nearby-events',
  props: {
    incident: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    headers: [
      { text: 'Type', value: 'type' },
      { text: 'Event', value: 'description' },
      { text: 'Year', value: 'year' },
      { text: 'Distance from incident [m]', value: 'distance' }
    ]
  }),
  computed: {
    nearbyEvents () {
      return this.incident?.nearby_events.map(e => {
        const temp = Object.assign({}, e)
        temp.distance = Number(temp.distance.toFixed(3))
        return temp
      }) || []
    }
  }
}
</script>
